@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

.dark {
    --background: #0a192f;
    --textmaincolor: #e5e7eb;
    --textcolor: #8892b0;
    --buttonbg: #db2777;
    --border-color: #fff;
    --nameTxt: #db2777;
    --logo: url('assets/PortfolioWhite.png');
    --skillsColor: #40679c;
}


/* .light {
    --background: #e5e7eb;
    --textmaincolor: #0a192f;
    --textcolor: #3e4856;
    --buttonbg: #656565;
    --border-color: #000;
    --nameTxt: #0a192f;
    --logo: url('assets/PortfolioTrans.png');
    --skillsColor: #3e4856;
} */

.contactColor {
    color: #000;
}

.indexN {
    z-index: 10;
}

.workField {
    cursor: pointer;
}

.skillsShow {
    border-radius: 6px;
    background-color: var(--skillsColor);
    color: #fff;

}

.img {
    content: var(--logo);
}

.nameText {
    color: var(--nameTxt) !important;
    fill: var(--nameTxt);
}

.buttonBg {
    border-color: var(--border-color);
    color: var(--textmaincolor);
}

.buttonBg:hover {
    background-color: var(--buttonbg);
    border-color: var(--buttonbg);
    color: #fff;
}

.background {
    background-color: var(--background)
}

.textmain {
    color: var(--textmaincolor)
}

.textcolor {
    color: var(--textcolor)
}

.sizeImg {
    width: 230px;
    padding-top: 30px;
    cursor: pointer;
}

.sizeImgProj {
    width: 500px;
    height: 500px;
    padding: 30px;
}

.mobileToggle {
    margin-top: 20px;
    margin-left: 20px
}

@layer base {
    body {
        @apply font-[Raleway]
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

@media (min-width: 768px) {
    .Moblie-menu {
        display: none;
    }
}

@media (max-width: 768px) {
    .skillsHi {
        height: 150vh;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}

a {
    margin: 0px;
    padding: 0;
}

.gallery {
    width: 100%;
    height: 100vh;
    background: #000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.galleryImg {
    width: auto;
    max-width: 90%;
    max-height: 90%;
}